import { useEffect, useState } from 'react';
import { getOrFetchProductTags } from '@/utils/getProductTags';

export default function useProductTags() {
  const [productTags, setProductTags] = useState({});

  useEffect(() => {
    async function getProductTags() {
      const tags = await getOrFetchProductTags();
      setProductTags(tags);
    }
    getProductTags();
  }, []);

  return { productTags };
}
