import { BehaviorSubject } from 'rxjs';

const subjectTagsFetched = new BehaviorSubject(false);

const ProductTagsFetched = {
  update: fetched => {
    subjectTagsFetched.next(fetched);
  },
};

const ProductTagsStore = {
  subject: new BehaviorSubject({}),
  update: tags => {
    const tagsDictionary =
      tags.length > 0
        ? tags.reduce((result, tag) => {
            result[tag.id] = tag;
            return result;
          }, {})
        : {};
    ProductTagsStore.subject.next(tagsDictionary);
    ProductTagsFetched.update(true);
  },
  subscribe: setState => ProductTagsStore.subject.subscribe(setState),
  getValue: () => ProductTagsStore.subject.value,
  isFetched: () => subjectTagsFetched.value,
};

export default ProductTagsStore;
