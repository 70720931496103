import useWindowUnderThreshold from '@/hooks/useWindowUnderThreshold';
import { selectPromotion, bannersAnalyticsFunction } from '@/utils/analytics.js';
import { HomePageBanner } from '@desquared/wave-components-library';
import ProductsAside from '../Aside/ProductsAside';
import { ProductsSideSheetStore } from '@/store/sideSheet';
import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function BannerSection(props) {
  const [index, setIndex] = useState(0);
  const isMobile = useWindowUnderThreshold(850);

  const openSideSheet = (index, isMobile) => {
    setIndex(index);
    ProductsSideSheetStore.open();
  };
  return (
    <>
      <HomePageBanner
        isFixedWidth={props.fixedWidth}
        showPagination={props?.showPagination !== undefined ? props?.showPagination : true}
        refreshTime={10000}
        images={props.images}
        isMobile={isMobile}
        isVideo={props.isVideo}
        type={props.type}
        ImageComponent={Image}
        LinkComponent={Link}
        analyticsFunction={bannersAnalyticsFunction}
        onClick={
          props?.products
            ? index => {
                openSideSheet();
                selectPromotion({
                  creative_name: props.images[index]?.altText ?? 'homepage_carousel',
                  creative_slot: props.images[index]?.altText ?? 'homepage_carousel',
                  promotion_id: props.images[index]?.altText ?? 'homepage_carousel',
                  promotion_name: props.images[index]?.altText ?? 'homepage_carousel',
                });
              }
            : index => {
                selectPromotion({
                  creative_name: props.images[index]?.altText ?? 'homepage_carousel',
                  creative_slot: props.images[index]?.altText ?? 'homepage_carousel',
                  promotion_id: props.images[index]?.altText ?? 'homepage_carousel',
                  promotion_name: props.images[index]?.altText ?? 'homepage_carousel',
                });
              }
        }
      />
      {props?.products && (
        <ProductsAside products={props.products[index]} isMobile={isMobile} title={props.titles[index]} />
      )}
    </>
  );
}
