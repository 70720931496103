import CustomImage from '@/components/CustomImage/CustomImage.jsx';
import { ProductAisleV2, SnackBar, OffersSnackbar } from '@desquared/wave-components-library';
import { useRouter } from 'next/router';
import React, { useState, useEffect, useContext } from 'react';
import {
  addProduct,
  removeProduct,
  transformProduct,
  openListSideSheet,
  getBundleOfferDescription,
  getProductPropsWithCleanUrl,
  getProductBasedOnSlug,
  getProductIndexBasedOnSlug,
} from '../../utils/products';
import { enhanceProductWithSlug } from '@/utils/products/productSlugs';
import { getV31AvailableProducts } from '@/api/Products';
import { checkListStatus } from '@/utils/lists';
import useProductTags from '@/hooks/useProductTags';
import useBundleOffersSummary from '@/hooks/useBundleOffersSummary';
import { productsForOrder } from '@/store/cart';
import { BundleOfferSnackBarOpen } from '@/store/bundleOfferSnackBar';
import { BundleOfferSideSheetStore } from '@/store/sideSheet';
import { SelectedBundleOfferStore } from '@/store/selectedBundleOfferId';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { CurrencyContext } from '@/contexts/CurrencyProvider';
import { getProductInfo, selectItem } from '@/utils/analytics.js';

export default function ProductsAisle({
  products,
  title,
  productsLink,
  linkText,
  isMobile,
  hasPadding,
  limitations,
  showMore,
  horizontal,
  hideLastTile,
  defaultProductImage,
  analyticsFunction,
  list,
}) {
  const router = useRouter();
  const [homePageProducts, setHomePageProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [showSnackBar, setShowSnackbar] = useState(false);
  const { productTags } = useProductTags();
  const { bundleOffersSummary } = useBundleOffersSummary();
  const [productsOrder, setProductsOrder] = useState();
  const [showBundleOffersSnackBar, setShowBundleOffersSnackBar] = useState(false);
  const { getCollectionTypes, selectedShop } = useContext(DeliveryServicesContext);
  const t = useTranslations('common');
  const { formatter } = useContext(CurrencyContext);

  useEffect(() => {
    const BundleOfferSnackBarOpen$ = BundleOfferSnackBarOpen.subscribe(setShowBundleOffersSnackBar);

    const productsForOrder$ = productsForOrder.subscribe(setProductsOrder);
    function getHomePageProducts(productsSkus) {
      const selectedCollectionTypes = getCollectionTypes();
      getV31AvailableProducts(productsSkus, selectedCollectionTypes, router.locale).then(result => {
        if (result) {
          const _newProducts =
            result.length > 0
              ? result
                  .map(product =>
                    transformProduct(product, setShowSnackbar, limitations, productTags, bundleOffersSummary)
                  )
                  .map(p => ({ ...p, categoryIds: p.categories, _id: p.id }))
                  .map(enhanceProductWithSlug)
                  .map(p => {
                    let result = p.slug.slice(1);
                    return { ...p, slug: result };
                  })
              : [];

          let sortedArray = productsSkus
            .map(sku => _newProducts.find(product => product.sku === sku))
            .filter(product => product !== undefined);
          setNewProducts(sortedArray);
          setHomePageProducts(result);
        }
      });
    }

    getHomePageProducts(products);
    // eslint-disable-next-line
    return () => {
      productsForOrder$.unsubscribe();
      BundleOfferSnackBarOpen$.unsubscribe();
    };
  }, [productTags, bundleOffersSummary, selectedShop]);

  useEffect(() => {
    const _newProducts =
      homePageProducts.length > 0
        ? homePageProducts
            .map(product => transformProduct(product, setShowSnackbar, limitations, productTags, bundleOffersSummary))
            .map(p => ({ ...p, categoryIds: p.categories, _id: p.id }))
            .map(enhanceProductWithSlug)
            .map(p => {
              let result = p.slug.slice(1);
              return { ...p, slug: result };
            })
        : [];
    let sortedArray = products
      .map(sku => _newProducts.find(product => product.sku === sku))
      .filter(product => product !== undefined);
    setNewProducts(sortedArray);
  }, [productTags, bundleOffersSummary, productsOrder]);

  if (!products || products.length === 0 || newProducts.length === 0) return null;

  return (
    <>
      <ProductAisleV2
        title={title}
        analyticsFunction={analyticsFunction}
        list={list}
        products={newProducts}
        productsLink={productsLink}
        linkText={linkText}
        tilesSize={'default'}
        isMobile={isMobile}
        hasPadding={hasPadding}
        onListClick={openListSideSheet}
        checkStatus={checkListStatus}
        addProduct={product =>
          addProduct(
            product,
            false,
            {
              item_list_id: 'productsAisle',
              item_list_name: 'productsAisle',
            },
            false,
            t('asides.side_cart.no_comment')
          )
        }
        removeProduct={product =>
          removeProduct(
            product,
            {
              item_list_id: 'productsAisle',
              item_list_name: 'productsAisle',
            },
            t('asides.side_cart.no_comment')
          )
        }
        showMore={showMore && showMore !== 'undefined' ? showMore : true}
        allText={t('product_aisles.show_more_default_text')}
        horizontal={horizontal}
        hideLastTile={hideLastTile}
        defaultQsText={t('qs.default')}
        defaultQsUnavailableText={t('qs.default_unavailable')}
        ImageComponent={CustomImage}
        offerLabelHasRibbon={process.env.offerLabelHasRibbon}
        isTileDetailsAnimated={process.env.isTileDetailsAnimated}
        ctaIcon={process.env.ctaIcon}
        listIcon={process.env.listIcon}
        // LinkComponent={props => {
        //   return <Link prefetch={false} href={props.href} {...props} />;
        // }}
        LinkComponent={props => {
          const propsWithCleanUrl = getProductPropsWithCleanUrl(props);
          return (
            <Link
              prefetch={false}
              {...propsWithCleanUrl}
              onClick={() => {
                const product = getProductBasedOnSlug(newProducts, propsWithCleanUrl.href);

                if (product) {
                  selectItem(getProductInfo(product?.originalValues), {
                    item_list_id: 'homepage',
                    item_list_name: 'homepage',
                    index: getProductIndexBasedOnSlug(propsWithCleanUrl.href) + 1,
                  });
                }
              }}
            />
          );
        }}
        setMaxSnackBar={setShowSnackbar}
        currencyFormatter={formatter}
        defaultProductImage={defaultProductImage}
      />
      <SnackBar open={showSnackBar} setOpen={setShowSnackbar} text={t('snackbar')} position={'center'} />
      <OffersSnackbar
        emoji="🎉"
        open={showBundleOffersSnackBar}
        setOpen={BundleOfferSnackBarOpen.update}
        hasCloseOption={true}
        position="center"
        type="warning"
        autoWidth={true}
        buttonText={t('offers_snack_bar.btn_label')}
        text={getBundleOfferDescription(SelectedBundleOfferStore.getValue(), bundleOffersSummary)}
        onButtonPress={() => {
          setShowBundleOffersSnackBar(false);
          BundleOfferSnackBarOpen.update(false);
          BundleOfferSideSheetStore.update(true);
        }}
        autoHideDuration={8000}
        closeOnClickAway={true}
        hasShadow={true}
      />
    </>
  );
}
