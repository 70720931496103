import ProductTagsStore from '@/store/productTags';
import { getV31Tags } from '../api/tags';

export function getOrFetchProductTags() {
  if (ProductTagsStore.isFetched()) {
    return ProductTagsStore.getValue();
  }
  return updateProductTags();
}

export async function updateProductTags() {
  const tags = await getV31Tags('PRODUCTS');
  ProductTagsStore.update(tags);
  return ProductTagsStore.getValue();
}

export default { getOrFetchProductTags, updateProductTags };
