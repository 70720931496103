/**
 * Use to early return in useEffect.
 * Usage example `return earlyInUseEffect;`
 */
export function earlyInUseEffect() {
  return {};
}

export const sortArray = (arrayA, arrayB) => {
  const sortedArray = arrayB.sort((a, b) => {
    const indexA = arrayA.findIndex(item => item.id === a.id);
    const indexB = arrayA.findIndex(item => item.id === b.id);
    return indexA - indexB;
  });

  return sortedArray;
};
