import { isEmpty } from 'lodash';
import { wave } from './config';

export async function getV31Tags(context) {
  try {
    const query = isEmpty(context) ? '' : `?context=${context}`;
    const response = await wave.get(`v3.1/tags${query}`, {});

    return response.data;
  } catch (error) {
    return { error };
  }
}
