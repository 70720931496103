import CustomImage from '@/components/CustomImage/CustomImage.jsx';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { ProductCatalogueV2, SideSheet } from '@desquared/wave-components-library';
import { ProductsSideSheetStore } from '@/store/sideSheet';
import styles from './Aside.module.css';
import { getProductInfo, selectItem } from '@/utils/analytics.js';
import {
  addProduct,
  removeProduct,
  openListSideSheet,
  transformProduct,
  getProductPropsWithCleanUrl,
  getProductBasedOnSlug,
  getProductIndexBasedOnSlug,
} from '@/utils/products';
import { checkListStatus } from '@/utils/lists';
import useProductTags from '@/hooks/useProductTags';
import useBundleOffersSummary from '@/hooks/useBundleOffersSummary';
import { getV31AvailableProducts } from '@/api/Products';
import { productsForOrder } from '@/store/cart';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import { useTranslations } from 'use-intl';
import { enhanceProductWithSlug } from '@/utils/products/productSlugs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useGetDefaultProductImage from '@/hooks/useGetDefaultProductImage';

export default function ProductsAside(props) {
  const [sideSheet, setSideSheet] = useState(false);
  const [homePageProducts, setHomePageProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [showSnackBar, setShowSnackbar] = useState(false);
  const { productTags } = useProductTags();
  const [productsOrder, setProductsOrder] = useState();
  const { bundleOffersSummary } = useBundleOffersSummary();
  const { getCollectionTypes, selectedUserAddressIndex } = useContext(DeliveryServicesContext);
  const refOne = useRef(null);
  const t = useTranslations('common');
  const router = useRouter();
  const defaultProductImage = useGetDefaultProductImage(0);

  const onClick = () => {
    setTimeout(() => {
      ProductsSideSheetStore.close();
    }, '300');
  };

  useEffect(() => {
    const ProductsSideSheetStore$ = ProductsSideSheetStore.subscribe(setSideSheet);
    const productsForOrder$ = productsForOrder.subscribe(setProductsOrder);
    function getHomePageProducts(productsSkus) {
      const selectedCollectionTypes = getCollectionTypes();
      getV31AvailableProducts(productsSkus, selectedCollectionTypes, router.locale).then(result => {
        if (result) {
          const _newProducts =
            result.length > 0
              ? result
                  .map(product => transformProduct(product, setShowSnackbar, null, productTags, bundleOffersSummary))
                  .map(p => ({ ...p, categoryIds: p.categories, _id: p.id }))
                  .map(enhanceProductWithSlug)
                  .map(p => {
                    let result = p.slug.slice(1);
                    return { ...p, slug: result };
                  })
              : [];

          setNewProducts(_newProducts);
          setHomePageProducts(result);
        }
      });
    }
    getHomePageProducts(props?.products);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      ProductsSideSheetStore$.unsubscribe();
      productsForOrder$.unsubscribe();
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    const _newProducts =
      homePageProducts.length > 0
        ? homePageProducts.map(product =>
            transformProduct(product, setShowSnackbar, undefined, productTags, bundleOffersSummary)
          )
        : [];
    setNewProducts(_newProducts);
  }, [productTags, bundleOffersSummary, productsOrder]);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      ProductsSideSheetStore.close();
    }
  };

  if (!sideSheet) return null;

  return (
    <div ref={refOne}>
      <SideSheet header={props.title} hasClose={true} onClose={onClick} isMedium={true}>
        <ProductCatalogueV2
          title={''}
          products={newProducts}
          onListClick={openListSideSheet}
          checkStatus={checkListStatus}
          addProduct={product =>
            addProduct(
              product,
              false,
              {
                item_list_id: 'productsAside',
                item_list_name: 'productsAside',
              },
              false,
              t('asides.side_cart.no_comment')
            )
          }
          removeProduct={product =>
            removeProduct(
              product,
              {
                item_list_id: 'productsAside',
                item_list_name: 'productsAside',
              },
              t('asides.side_cart.no_comment')
            )
          }
          classOption={styles.sideSheetCatalogue}
          defaultQsText={t('qs.default')}
          defaultQsUnavailableText={t('qs.default_unavailable')}
          //   LinkComponent={props => <Link href={props.href || '#'}>{props.children}</Link>}
          ImageComponent={CustomImage}
          offerLabelHasRibbon={process.env.offerLabelHasRibbon}
          isTileDetailsAnimated={process.env.isTileDetailsAnimated}
          ctaIcon={process.env.ctaIcon}
          listIcon={process.env.listIcon}
          LinkComponent={props => {
            const propsWithCleanUrl = getProductPropsWithCleanUrl(props);

            return (
              <Link
                prefetch={false}
                {...propsWithCleanUrl}
                onClick={() => {
                  const product = getProductBasedOnSlug(newProducts, propsWithCleanUrl.href);
                  ProductsSideSheetStore.close();
                  if (product) {
                    selectItem(getProductInfo(product.originalValues), {
                      item_list_id: 'category',
                      item_list_name: product.category,
                      index: getProductIndexBasedOnSlug(propsWithCleanUrl.href) + 1,
                    });
                  }
                  router.push(product.slug);
                }}
              />
            );
          }}
          defaultProductImage={defaultProductImage}
        />
      </SideSheet>
    </div>
  );
}
